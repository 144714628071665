import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './Dashboard.css';

interface Task {
  id: string;
  properties: {
    Name: { title: [{ plain_text: string }] };
    Date: { date: { start: string } };
    Person: { rich_text: [{ plain_text: string }] };
    Tasks: { rich_text: [{ plain_text: string }] };
    'Success Rate': { rich_text: [{ plain_text: string }] };
    'Total Items': { number: number };
    'Completed Items': { number: number };
  };
}

const fetchNotionData = async () => {
  const response = await axios.get('/api/notion-data');
  return response.data.results;
};

const getMonthlyWeekNumber = (dateStr: string) => {
  const date = dayjs(dateStr);
  const startOfMonth = date.startOf('month');
  
  // 첫째 주는 해당 달의 첫날부터 첫 번째 토요일까지로 설정
  const firstSaturday = startOfMonth.day() === 6 ? startOfMonth : startOfMonth.add(6 - startOfMonth.day(), 'day');
  
  // 첫째 주: 첫날부터 첫 토요일까지
  if (date.isSame(startOfMonth, 'day') || date.isBefore(firstSaturday, 'day')) {
    return `${date.format('M')}월 1W`;
  }

  // 둘째 주 이후: 일요일 시작으로 주차 계산
  const firstSundayAfterFirstSaturday = firstSaturday.add(1, 'day');
  const weekNumber = Math.floor(date.diff(firstSundayAfterFirstSaturday, 'day') / 7) + 2;

  return `${date.format('M')}월 ${weekNumber}W`;
};



const calculateAverageSuccessRate = (tasks: Task[]) => {
  const totalSuccessRate = tasks.reduce((sum, task) => {
    const rate = parseFloat(task.properties['Success Rate'].rich_text[0]?.plain_text) || 0;
    return sum + rate;
  }, 0);
  return (totalSuccessRate / tasks.length).toFixed(2);
};

const Dashboard: React.FC = () => {
  const { data, isLoading, error } = useQuery<Task[], Error>('notionData', fetchNotionData);
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf('month'));

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!Array.isArray(data)) return <div>No data available</div>;

  const handleMonthChange = (offset: number) => {
    setCurrentMonth(currentMonth.add(offset, 'month'));
  };

  const filteredData = data.filter(task => {
    const taskDate = dayjs(task.properties.Date.date.start);
    return (
      (!selectedPerson || task.properties.Person.rich_text[0].plain_text === selectedPerson) &&
      taskDate.isSame(currentMonth, 'month')
    );
  });

  const uniquePersons = Array.from(new Set(data.map(task => task.properties.Person.rich_text[0].plain_text)));

  const groupedData = filteredData.reduce((weeks, task) => {
    const weekLabel = getMonthlyWeekNumber(task.properties.Date.date.start);
    if (!weeks[weekLabel]) weeks[weekLabel] = [];
    weeks[weekLabel].push(task);
    return weeks;
  }, {} as Record<string, Task[]>);

  // 일주일치 성공률 데이터를 날짜별로 수집
  const weekData = Object.entries(groupedData).map(([week, tasks]) => {
    const dailySuccessRates = tasks.reduce((days, task) => {
      const taskDate = dayjs(task.properties.Date.date.start).format('YYYY-MM-DD');
      const successRate = parseFloat(task.properties['Success Rate'].rich_text[0]?.plain_text) || 0;

      if (!days[taskDate]) {
        days[taskDate] = [];
      }
      days[taskDate].push(successRate);

      return days;
    }, {} as Record<string, number[]>);

    const dailyAverages = Object.entries(dailySuccessRates).map(([date, rates]) => ({
      date,
      average: rates.reduce((sum, rate) => sum + rate, 0) / rates.length,
    }));

    return { week, dailyAverages, tasks };
  });

  return (
    <div className="dashboard">
      <h1>&#127775; Task Dashboard &#127775;</h1>
      <div className="controls">
        <button onClick={() => handleMonthChange(-1)}>←</button>
        <span>{currentMonth.format('MMMM YYYY')}</span>
        <button onClick={() => handleMonthChange(1)}>→</button>
      </div>
      <label>
        Select Person:
        <select value={selectedPerson} onChange={(e) => setSelectedPerson(e.target.value)}>
          <option value="">All</option>
          {uniquePersons.map(person => (
            <option key={person} value={person}>{person}</option>
          ))}
        </select>
      </label>

      {weekData.map(({ week, dailyAverages, tasks }) => (
  <div key={week} className="weekly-section">
    <h2>
      {week} - 평균 달성률: {calculateAverageSuccessRate(tasks)}%
    </h2>
          <Line
            data={{
              labels: dailyAverages.map(d => d.date).reverse(),  // 날짜 역순 정렬
              datasets: [
                {
                  label: 'Daily Success Rate',
                  data: dailyAverages.map(d => d.average).reverse(),  // 성공률도 역순 정렬
                  fill: false,
                  borderColor: 'rgba(75,192,192,1)',
                  tension: 0.1
                }
              ]
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  max: 100
                }
              }
            }}
          />
<table className="styled-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Person</th>
                <th>Tasks</th>
                <th>Success Rate</th>
                <th>Total Items</th>
                <th>Completed Items</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(task => (
                <tr key={task.id}>
                  <td data-label="Date">{task.properties.Date.date.start}</td>
                  <td data-label="Person">{task.properties.Person.rich_text[0].plain_text}</td>
                  <td data-label="Tasks">{task.properties.Tasks.rich_text[0].plain_text}</td>
                  <td data-label="Success Rate">{task.properties['Success Rate'].rich_text[0].plain_text}</td>
                  <td data-label="Total Items">{task.properties['Total Items'].number}</td>
                  <td data-label="Completed Items">{task.properties['Completed Items'].number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
